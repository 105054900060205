/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/iframe-has-title */
import './App.less';
import $ from 'jquery';
import React, { useState, useEffect, useRef } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function Collection() {
  let sliderRef = useRef(null);
  const [list, setList] = useState([])
  const [heiList, setHeiList] = useState([])
  const [iframeUrl,setIframeUrl] = useState(list.length > 0 ? list[0].src : '')
  const [height, setHeight] = useState();
  const [width, setWidth] = useState();
  const [settings, setSetting] = useState({
    // dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  })

  useEffect(() => {
    getListData()
    // 设置屏幕监听
    window.addEventListener("resize", handleWindowResize)
    return () => {
        window.removeEventListener("resize", handleWindowResize)
    }
  }, [])

  const getListData = () => {
    let headList = [{
      img: require('./image/3.png'),
      str: '写材料',
      funBool: true
    },{
      img: require('./image/4.png'),
      str: '找材料',
      funBool: true
    },{
      img: require('./image/1.png'),
      str: '做PPT',
      funBool: false
    },{
      img: require('./image/2.png'),
      str: '做图片',
      funBool: false
    }]
    setHeiList(headList)
    let list = [
        {
          "name": "DeepSeek-R1",
          "img": "https://geyuan-back.oss-cn-beijing.aliyuncs.com/AI%E5%AE%9D%E5%BA%93/images/deepseek.png",
          "hint": "幻方量化旗下深度求索推出的开源大模型和聊天助手",
          "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=paclccf8el9o1ue5ly75tv27"
        },
        {
          "name": "DeepSeek-V3",
          "img": "https://geyuan-back.oss-cn-beijing.aliyuncs.com/AI%E5%AE%9D%E5%BA%93/images/deepseek.png",
          "hint": "幻方量化旗下深度求索推出的开源大模型和聊天助手",
          "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=5syrca0s2ajb3wt1xfzhunf7"
        },
        {
            "name": "豆包",
            "img": "https://geyuan-back.oss-cn-beijing.aliyuncs.com/AI%E5%AE%9D%E5%BA%93/images/doubao.png",
            "hint": "字节跳动推出的免费AI对话助手",
            "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=qorgzkuufog4n319h8s5d28e"
        },
        {
            "name": "文心一言",
            "img": "https://geyuan-back.oss-cn-beijing.aliyuncs.com/AI%E5%AE%9D%E5%BA%93/images/wenxin.png",
            "hint": "百度推出的基于文心大模型的AI对话互动工具",
            "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=bzd5fphfsjzcawwyaj8jbkm2"
        },
        {
            "name": "腾讯混元",
            "img": "https://geyuan-back.oss-cn-beijing.aliyuncs.com/AI%E5%AE%9D%E5%BA%93/images/hunyuan.png",
            "hint": "腾讯推出的免费AI智能助手",
            "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=qfzo19pd2swmlwc7g9hlg5hu"
        },
        {
            "name": "智谱清言",
            "img": "https://geyuan-back.oss-cn-beijing.aliyuncs.com/AI%E5%AE%9D%E5%BA%93/images/zhipu.png",
            "hint": "智谱AI推出的生成式AI助手，基于ChatGLM 2",
            "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=mjxdbb8irnlo1240d1465f9t"
        },
        {
            "name": "通义千问",
            "img": "https://geyuan-back.oss-cn-beijing.aliyuncs.com/AI%E5%AE%9D%E5%BA%93/images/tongyi.png",
            "hint": "阿里巴巴推出的类ChatGPT响应人类指令的大模型",
            "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=axq1po71r759nniowynz366s"
        },
        {
            "name": "Kimi",
            "img": "https://geyuan-back.oss-cn-beijing.aliyuncs.com/AI%E5%AE%9D%E5%BA%93/images/kimi.png",
            "hint": "超大“内存”助手，可以一口气读完二十万字的小说",
            "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=0k5kl2zgfc2qoae33rnzttgh"
        },
        {
            "name": "讯飞星火",
            "img": "https://geyuan-back.oss-cn-beijing.aliyuncs.com/AI%E5%AE%9D%E5%BA%93/images/xunfei.png",
            "hint": "科大讯飞推出的类ChatGPT的讯飞星火认知大模型",
            "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=fbkd6jrv5r8elllaatbf08rz"
        },
        {
            "name": "360智脑",
            "img": "https://geyuan-back.oss-cn-beijing.aliyuncs.com/AI%E5%AE%9D%E5%BA%93/images/360.png",
            "hint": "360搜索最新推出的AI对话聊天机器人",
            "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=uti3u0l1w5345rnldmde3qu1"
        },
        {
            "name": "百小应",
            "img": "https://geyuan-back.oss-cn-beijing.aliyuncs.com/AI%E5%AE%9D%E5%BA%93/images/baixiao.jpg",
            "hint": "百川智能推出的免费AI助手",
            "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=gyuet1ji1x62mal30rteakul"
        },
        {
            "name": "天工",
            "img": "https://geyuan-back.oss-cn-beijing.aliyuncs.com/AI%E5%AE%9D%E5%BA%93/images/tiangong.png",
            "hint": "昆仑万维最新推出的结合大模型的AI搜索引擎",
            "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=fl2ckj71yzvmbgod4ym02402"
        },
        {
            "name": "法律顾问",
            "img": "https://ai-bot.cn/wp-content/uploads/2024/04/tongyi-icon.png",
            "hint": "法律顾问",
            "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=sk1rbbudejls0255oubski2o"
        },
        {
            "name": "ChatGPT",
            "img": "https://ai-bot.cn/wp-content/uploads/2023/03/openai-gpt-model.png",
            "hint": "ChatGPT",
            "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=vpm816ww4dzioy68j1nibu7v"
        }
    ]
    setList(list);
    let localStr = localStorage.getItem('localIframe');
    if(localStr !== null) {
      setIframeUrl(localStr)
    } else {
      setIframeUrl(list.length > 0 ? list[0].src : '')
    }
    setSliderOpe()
  }

  const handleWindowResize = () => {
    setSliderOpe()
  }

  const setSliderOpe = () => {
    let sliderHei = (document.documentElement.scrollHeight) - 120;
    setHeight(sliderHei)
  }

  const changeSlider = (index) => {
    let list = [];
    if(index == 0) {
       list = [
        {
          "name": "DeepSeek-R1",
          "img": "https://geyuan-back.oss-cn-beijing.aliyuncs.com/AI%E5%AE%9D%E5%BA%93/images/deepseek.png",
          "hint": "幻方量化旗下深度求索推出的开源大模型和聊天助手",
          "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=paclccf8el9o1ue5ly75tv27"
        },
        {
          "name": "DeepSeek-V3",
          "img": "https://geyuan-back.oss-cn-beijing.aliyuncs.com/AI%E5%AE%9D%E5%BA%93/images/deepseek.png",
          "hint": "幻方量化旗下深度求索推出的开源大模型和聊天助手",
          "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=5syrca0s2ajb3wt1xfzhunf7"
        },
        {
            "name": "豆包",
            "img": "https://geyuan-back.oss-cn-beijing.aliyuncs.com/AI%E5%AE%9D%E5%BA%93/images/doubao.png",
            "hint": "字节跳动推出的免费AI对话助手",
            "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=qorgzkuufog4n319h8s5d28e"
        },
        {
            "name": "文心一言",
            "img": "https://geyuan-back.oss-cn-beijing.aliyuncs.com/AI%E5%AE%9D%E5%BA%93/images/wenxin.png",
            "hint": "百度推出的基于文心大模型的AI对话互动工具",
            "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=bzd5fphfsjzcawwyaj8jbkm2"
        },
        {
            "name": "腾讯混元",
            "img": "https://geyuan-back.oss-cn-beijing.aliyuncs.com/AI%E5%AE%9D%E5%BA%93/images/hunyuan.png",
            "hint": "腾讯推出的免费AI智能助手",
            "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=qfzo19pd2swmlwc7g9hlg5hu"
        },
        {
            "name": "智谱清言",
            "img": "https://geyuan-back.oss-cn-beijing.aliyuncs.com/AI%E5%AE%9D%E5%BA%93/images/zhipu.png",
            "hint": "智谱AI推出的生成式AI助手，基于ChatGLM 2",
            "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=mjxdbb8irnlo1240d1465f9t"
        },
        {
            "name": "通义千问",
            "img": "https://geyuan-back.oss-cn-beijing.aliyuncs.com/AI%E5%AE%9D%E5%BA%93/images/tongyi.png",
            "hint": "阿里巴巴推出的类ChatGPT响应人类指令的大模型",
            "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=axq1po71r759nniowynz366s"
        },
        {
            "name": "Kimi",
            "img": "https://geyuan-back.oss-cn-beijing.aliyuncs.com/AI%E5%AE%9D%E5%BA%93/images/kimi.png",
            "hint": "超大“内存”助手，可以一口气读完二十万字的小说",
            "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=0k5kl2zgfc2qoae33rnzttgh"
        },
        {
            "name": "讯飞星火",
            "img": "https://geyuan-back.oss-cn-beijing.aliyuncs.com/AI%E5%AE%9D%E5%BA%93/images/xunfei.png",
            "hint": "科大讯飞推出的类ChatGPT的讯飞星火认知大模型",
            "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=fbkd6jrv5r8elllaatbf08rz"
        },
        {
            "name": "360智脑",
            "img": "https://geyuan-back.oss-cn-beijing.aliyuncs.com/AI%E5%AE%9D%E5%BA%93/images/360.png",
            "hint": "360搜索最新推出的AI对话聊天机器人",
            "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=uti3u0l1w5345rnldmde3qu1"
        },
        {
            "name": "百小应",
            "img": "https://geyuan-back.oss-cn-beijing.aliyuncs.com/AI%E5%AE%9D%E5%BA%93/images/baixiao.jpg",
            "hint": "百川智能推出的免费AI助手",
            "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=gyuet1ji1x62mal30rteakul"
        },
        {
            "name": "天工",
            "img": "https://geyuan-back.oss-cn-beijing.aliyuncs.com/AI%E5%AE%9D%E5%BA%93/images/tiangong.png",
            "hint": "昆仑万维最新推出的结合大模型的AI搜索引擎",
            "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=fl2ckj71yzvmbgod4ym02402"
        },
        {
            "name": "法律顾问",
            "img": "https://ai-bot.cn/wp-content/uploads/2024/04/tongyi-icon.png",
            "hint": "法律顾问",
            "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=sk1rbbudejls0255oubski2o"
        },
        {
            "name": "ChatGPT",
            "img": "https://ai-bot.cn/wp-content/uploads/2023/03/openai-gpt-model.png",
            "hint": "ChatGPT",
            "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=vpm816ww4dzioy68j1nibu7v"
        }
      ]
      setList(list);
      setIframeUrl(list.length > 0 ? list[0].src : '')
      setSliderOpe()
    } else if(index == 1) {
      list = [
        {
          "name": "秘塔",
          "img": "https://ai-bot.cn/wp-content/uploads/2024/06/metaso-ai-search-icon.png",
          "hint": "秘塔AI",
          "src": "https://metaso.cn/?s=sbypcpz&referrer_s=sbypcpz"
        }
      ]
      setList(list);
      setIframeUrl(list.length > 0 ? list[0].src : '')
      setSliderOpe()
    }
    
  }

  return (
    <div className="collection_con">
      <div className='collection_head'>
        <div className='collection_logo' onClick={() => {window.history.go(0)}}>
          <img src={require('./image/logo.png')} />
          AI百宝箱
        </div>
        {
          heiList.length > 0 ? heiList.map((item, index) => {
            return (
              <div className='slider_box' key={index} onClick={() => {changeSlider(index)}}>
                <div style={{width: 146}}>
                  <div className={item.funBool?'slider_chi':'slider_chi spe_box'}>
                    <img src={item.img}/>
                    <p>{item.str}</p>
                    <div style={{display: item.funBool?'none':'block'}} className='fun_box'>敬请期待</div>
                  </div>
                </div>
              </div>
            )}) : ''
        }
      </div>
      <div className='collection_body'>
        <div className='collection_cho'
          style={{
            height: height + "px",
          }}
        >
        {
          list.length > 0 ? list.map((item, index) => {
            return (
              <div key={index} className='collection_cho_item' onClick={() => {
                setIframeUrl(list[index].src)
                localStorage.setItem('localIframe', list[index].src)
              }}>
                <img src={item.img}/>
                <p>
                  {item.name}
                </p>
              </div>
            )}) : ''
        }
        </div>
        <iframe
          name="iframe"
          scrolling="auto"
          align="left" 
          src={iframeUrl}
        />
      </div>
    </div>
  )
}